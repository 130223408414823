import React, { useState } from 'react';

import moment from 'moment';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ScheduleIcon from '@material-ui/icons/Schedule';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';


import { muiTableStyle } from "../utils/theme";

import { Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { Button } from "@chakra-ui/react";

import { BASE_URL } from "../utils/constant"

import './Details.css'
import Element from './Element';
import API from '../services/api';
import { useToast } from "@chakra-ui/toast";

function Details({ 
    userImage, 
    firstName, 
    lastName, 
    email,
    type,
    createdAt, 
    last_active,
    planName,
    planFrequency,
    isMinutesIncreased,
    custom,
    subscribedAt,
    invites,
    _id
}) {

    const tableClasses = muiTableStyle();

    const [duration, setDuration] = useState();
    const [minutes, setMinutes] = useState();
    const [submitted, setSubmitted] = useState(false);

    const regex = new RegExp(/^\d*(\d+)?$/);

    const toast = useToast();

    const durationValidation = (regex.test(duration) && duration <= 366 && duration > 0);
    const minutesValidation = (regex.test(minutes) && minutes <= 1000 && duration > 0);

    // Set free users to premium for specific duration
    const handleSubmit = (event) => {
        event.preventDefault();
        if (durationValidation) {
            document.getElementById("premium_submit_button").setAttribute("disabled", true);
            const data = {
                duration: parseInt(duration),
                type: "Free",
                form: "premium_form",
                submitButton: "premium_submit_button"
            }
           setPremium(data);
        }
        else setSubmitted(true);
    }
    // Increase the monthly minutes of already premimum users
    const handleBenefitSubmit = (event) => {
        event.preventDefault();
        if (durationValidation && minutesValidation) {
            document.getElementById("benefit_submit_button").setAttribute("disabled", true);
            const data = {
                duration: parseInt(duration),
                minutes: parseInt(minutes),
                type: "Premium",
                form: "benefit_form",
                submitButton: "benefit_submit_button"
            }
            setPremium(data);
        }
        else setSubmitted(true);
    }

    const setPremium = (params) => {
        const data = {
            userId: _id,
            data: params,
            toast
        }
        API._setPremium(data).then((apiResponse) => {
            if (apiResponse && apiResponse.success) {
                document.getElementById(params.form).reset();
                if (params.type == "Premium") {
                    setMinutes();
                    setDuration();
                }
                setTimeout(function() { window.location.reload(); }, 2000);
            }
            setSubmitted(false);
        });
    }

    return (
        <div className="details">

            <Avatar 
                src={userImage ? `${BASE_URL}/uploads/images/${userImage}` : '.'} variant="rounded"
                style={{ height: '80px', width: '80px', marginBottom: '15px' }} 
            />

            <div className="details__block">
                <Element
                    label="Name"
                    value={`${firstName} ${lastName}`}
                    icon={<AlternateEmailIcon style={{ fontSize: '16px' }}/>}
                />
                <Element 
                    label="Email"
                    value={email}
                    icon={<AlternateEmailIcon style={{ fontSize: '16px' }}/>}
                />
                <Element 
                    label="Created on"
                    value={moment(createdAt).format('lll')}
                    icon={<CalendarTodayIcon style={{ fontSize: '16px' }}/>}
                />
                <Element 
                    label="Last active"
                    value={last_active ? moment(last_active).format('lll') : 'N/A'}
                    icon={<ScheduleIcon style={{ fontSize: '16px' }}/>}
                />
                <Element 
                    label="Type"
                    value={type ? type : 'Normal User'}
                    icon={<ScheduleIcon style={{ fontSize: '16px' }}/>}
                />
            </div>

            <h3>Plan details</h3>

            <div className="details__block">
                <Element 
                    label="Plan type"
                    value={`${planName}${planName === 'Premium' ? ` - ${planFrequency}` : ''}`}
                    icon={<ScheduleIcon style={{ fontSize: '16px' }}/>}
                />
                {
                    planFrequency === "Limited"
                    ?
                    <Element 
                    label="Plan duration"
                    value={`${custom.duration} days`}
                    icon={<ScheduleIcon style={{ fontSize: '16px' }}/>}
                    />
                    :
                    planName === 'Premium' && isMinutesIncreased === true
                    ?
                    <Element 
                    label="Plan minutes increased by "
                    value={`${custom.minutes} minutes`}
                    icon={<ScheduleIcon style={{ fontSize: '16px' }}/>}
                    />
                    :
                    ""
                }
                { planName === 'Premium' && (
                        <Element 
                            label="Subscribed on"
                            value={moment(subscribedAt).format('lll')}
                            icon={<CalendarTodayIcon style={{ fontSize: '16px' }}/>}
                        />
                    )
                }
            </div>

            {
                planName === 'Free' ?
                <>
                    <h3>Set Premium (In Days)</h3>

                    <div className="set_premium__block">
                        <form className='premium_block' id='premium_form'>
                            <div className={submitted && !durationValidation ? 'error_border premium_block_input': 'premium_block_input'}>
                                <input
                                    type="number"
                                    placeholder={`Enter duration`}
                                    value={duration}
                                    onChange={(e) => setDuration(e.target.value)}
                                />
                            </div>
                            <Button
                                colorScheme="brand"
                                size="sm"
                                px={"9px"}
                                py={"18px"}
                                type="submit"
                                onClick={handleSubmit}
                                id="premium_submit_button"
                                title="Submit"
                            >Submit</Button>
                        </form>
                        { submitted && !duration ? <div className="error">Please enter duration!</div> : "" }
                        { submitted && duration && !regex.test(duration) ? <div className="error">Duration must be a positive integer number!</div> : "" }
                        { submitted && duration && parseInt(duration) > 366 ? <div className="error">Duration cannot be greater than 366!</div> : "" }
                        { submitted && duration && parseInt(duration) < 1 ? <div className="error">Duration must be greater than 0!</div> : "" }
                    </div>
                </>
                :
                (planName === 'Premium' && planFrequency !== 'Limited' && !isMinutesIncreased) ?
                <>
                    <h3>Increase Monthly Minutes</h3>

                    <div className="set_premium__block">
                        <form className='benefit_block' id='benefit_form'>
                            <div className="input">
                                <div className={submitted && !durationValidation ? 'error_border benefit_block_input': 'benefit_block_input'}>
                                    <input
                                        type="number"
                                        placeholder={`Enter duration`}
                                        value={duration}
                                        onChange={(e) => setDuration(e.target.value)}
                                    />
                                </div>
                                { submitted && !duration ? <div className="error">Please enter duration!</div> : "" }
                                { submitted && duration && !regex.test(duration) ? <div className="error">Duration must be a positive integer number!</div> : "" }
                                { submitted && duration && parseInt(duration) > 366 ? <div className="error">Duration cannot be greater than 366!</div> : "" }
                                { submitted && duration && parseInt(duration) < 1 ? <div className="error">Duration must be greater than 0!</div> : "" }
                            </div>
                            <div className="input">
                                <div className={submitted && !minutesValidation ? 'error_border benefit_block_input': 'benefit_block_input'}>
                                    <input
                                        type="number"
                                        placeholder={`Enter minutes`}
                                        value={minutes}
                                        onChange={(e) => setMinutes(e.target.value)}
                                    />
                                </div>
                                { submitted && !minutes ? <div className="error">Please enter minutes!</div> : "" }
                                { submitted && minutes && !regex.test(minutes) ? <div className="error">Minutes must be a positive integer number!</div> : "" }
                                { submitted && minutes && parseInt(minutes) > 1000 ? <div className="error">Minutes cannot be greater than 1000!</div> : "" }
                                { submitted && minutes && parseInt(minutes) < 1 ? <div className="error">Minutes must be greater than 0!</div> : "" }
                            </div>
                            <Button
                                colorScheme="brand"
                                size="sm"
                                px={"9px"}
                                py={"18px"}
                                type="submit"
                                onClick={handleBenefitSubmit}
                                id="benefit_submit_button"
                                title="Submit"
                            >Submit</Button>
                        </form>
                    </div>
                </>
                :
                ''
            }
            
            <h3>Invitees</h3>

            <div className="details__invites">

                {
                    invites.length > 0 
                    ?
                    <TableContainer component={Paper} className={tableClasses.tableContainer}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={tableClasses.tableHeaderCell}>Email</TableCell>
                                    <TableCell className={tableClasses.tableHeaderCell}>Invited on</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invites.map((invite) => (
                                    <TableRow key={invite.email}>
                                        <TableCell className={tableClasses.tableRowCell}>
                                            <p>{invite.email}</p>
                                        </TableCell>
                                        <TableCell className={tableClasses.tableRowCell}>
                                            {moment(invite.createdAt).format("MMM Do, YY")}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    'No invites'
                }
            </div>

        </div>
    )
}

export default Details
