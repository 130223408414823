import React, { useState, useEffect } from 'react';

import { Box, Button, FormControl, Input, InputGroup, Select } from '@chakra-ui/react';

import CardHeader from '../CardHeader';
import Card from '../Card';
import { Field, Form, Formik } from 'formik';
import API from '../../services/api';
import Property from '../Property';
import { NavLink, useLocation } from "react-router-dom";

import { Editor } from "@tinymce/tinymce-react";

function ViewNotification() {

    const [formModel, setFormModel] = useState({
        userType: "",
        title: "",
        hyperlink: "",
        description: ""
    });

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };
    const query = useQuery();
      
    useEffect(() => {
        let id = query.get("id");
        API._getCustomNotificationById({id}).then((apiResponse) => {
            if (apiResponse && apiResponse.success) {
                setFormModel({
                    "userType": apiResponse.data.userType,
                    "title": apiResponse.data.title,
                    "hyperlink": apiResponse.data.hasOwnProperty("payload") ? apiResponse.data.payload.hyperlink : "",
                    "description": apiResponse.data.description
                });
            }
        });
    }, []);

    return (
        <Box as="section" py="12" px={{ md: '8' }}>
            <Card maxW="3xl" mx="auto">
                <CardHeader title="Custom Notification" />
                <Formik
                    initialValues={formModel}
                    enableReinitialize={true}
                >
                    {(props) => (
                    <Form>
                        <Box>

                            <Field name="userType">
                                {({ field, form }) => (
                                    <Property label="User Type" value={
                                        <FormControl>
                                            <InputGroup>
                                            <Select placeholder="Select User Type" {...field} disabled style={{ cursor: "default", opacity: 1}}>
                                              <option value="Free">Free</option>
                                              <option value="Premium">Premium</option>
                                              <option value="All">All</option>
                                            </Select>
                                            </InputGroup>
                                        </FormControl>
                                    }/>
                                )}
                            </Field>
                            <Field name="title">
                                {({ field, form }) => (
                                <Property label="Notification Title" value={
                                    <FormControl>
                                        <InputGroup>
                                            <Input type="text" id="title" {...field} isReadOnly/>
                                        </InputGroup>
                                    </FormControl>
                                }/>
                                )}
                            </Field>
                            <Field name="hyperlink">
                                {({ field }) => (
                                <Property label="External Link (Optional)" value={
                                    <FormControl>
                                        <InputGroup>
                                            <Input type="text" id="hyperlink" {...field} isReadOnly />
                                        </InputGroup>
                                    </FormControl>
                                }/>
                                )}
                            </Field>
                            <Field name="description">
                                {({ field, form }) => (
                                <Property label="Description" value={
                                    <FormControl>
                                        <InputGroup>
                                                <Editor
                                                    apiKey="tw1s9yvvrkx3uld5sc6fdcaqe12qcxnk2t74et84d6eyc8ra"
                                                    init={{
                                                        plugins: "emoticons",
                                                        toolbar: "bold italic emoticons",
                                                        toolbar_location: "bottom",
                                                        menubar: false,
                                                        statusbar: false,
                                                        height: 200,
                                                        width: 450
                                                    }}
                                                    id="description" {...field}
                                                    disabled
                                                />
                                            </InputGroup>
                                    </FormControl>
                                }/>
                                )}
                            </Field>
                            <Property label={
                                <NavLink to="/custom-notifications">
                                    <Button type="button" bg={'brand.100'} color={'white'} size={'md'} _hover={{ bg: 'brand.50' }} title="Back">
                                        Back
                                    </Button>
                                </NavLink>
                            } />
                        </Box>

                    </Form>
                    )}
                </Formik>
            </Card>
        </Box>
    )
}

export default ViewNotification;