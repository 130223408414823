import React, { useState } from 'react';

import { Box, Button, FormControl, FormErrorMessage, Input, InputGroup, Select, useToast } from '@chakra-ui/react';

import CardHeader from '../CardHeader';
import Card from '../Card';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import API from '../../services/api';
import Property from '../Property';
import { NavLink, useHistory } from "react-router-dom";

import { Editor } from "@tinymce/tinymce-react";

function SendNotification() {

    const history = useHistory();

    const formModel = { userType: "", title: "", hyperlink: "", description: "" }
    const formSchema = Yup.object().shape({
        userType: Yup.string().required('User is required'),
        title: Yup.string()
            .required('Title is required')
            .max(40, 'Title cannot have more than 40 characters'),
        hyperlink: Yup.string().matches(
            /^(http(s)?:\/\/.)(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?/,
            'Please enter a valid link!'
        ),
        description: Yup.string()
            .required('Description is required')
            .max(1000, 'Description cannot have more than 1000 characters')
    });

    // var desc = "";

    const toast = useToast();

    return (
        <Box as="section" py="12" px={{ md: '8' }}>
            <Card maxW="3xl" mx="auto">
                <CardHeader title="Custom Notification" />
                <Formik
                    initialValues={formModel}
                    validationSchema={formSchema}
                    onSubmit={async (values, actions) => {
                        let doc = new DOMParser().parseFromString(values.description, "text/html");
                        values.plan_description = doc.documentElement.textContent;
                        console.log('----my values ', values);
                        const apiResponse = await API._sendCustomNotifications({ values, toast })
                        if (apiResponse && apiResponse.success) {
                            // desc  = "";
                            actions.resetForm();
                            history.push("/custom-notifications");
                        }
                        actions.setSubmitting(false);
                    }}
                >
                    {(props) => (
                    <Form>
                        <Box>

                            <Field name="userType">
                                {({ field, form }) => (
                                    <Property label="User Type" value={
                                        <FormControl isInvalid={form.errors.userType && form.touched.userType}>
                                            <InputGroup>
                                            <Select placeholder="Select User Type" {...field}>
                                              <option value="Free">Free</option>
                                              <option value="Premium">Premium</option>
                                              <option value="All">All</option>
                                            </Select>
                                            </InputGroup>
                                            <FormErrorMessage>{form.errors.userType}</FormErrorMessage>
                                        </FormControl>
                                    }/>
                                )}
                            </Field>
                            <Field name="title">
                                {({ field, form }) => (
                                <Property label="Notification Title" value={
                                    <FormControl isInvalid={form.errors.title && form.touched.title}>
                                        <InputGroup>
                                            <Input type="text" id="title" {...field} />
                                        </InputGroup>
                                        <FormErrorMessage>{form.errors.title}</FormErrorMessage>
                                    </FormControl>
                                }/>
                                )}
                            </Field>
                            <Field name="hyperlink">
                                {({ field, form }) => (
                                <Property label="External Link (Optional)" value={
                                    <FormControl isInvalid={form.errors.hyperlink && form.touched.hyperlink}>
                                        <InputGroup>
                                            <Input type="text" id="hyperlink" {...field} />
                                        </InputGroup>
                                        <FormErrorMessage>{form.errors.hyperlink}</FormErrorMessage>
                                    </FormControl>
                                }/>
                                )}
                            </Field>
                            <Field name="description">
                                {({ field, form }) => (
                                <Property label="Description" value={
                                    <FormControl isInvalid={form.errors.description && form.touched.description}>
                                        <Editor
                                            apiKey="tw1s9yvvrkx3uld5sc6fdcaqe12qcxnk2t74et84d6eyc8ra"
                                            init={{
                                                plugins: "emoticons",
                                                toolbar: "bold italic emoticons",
                                                toolbar_location: "bottom",
                                                menubar: false,
                                                statusbar: false,
                                                height: 200,
                                                width: 450
                                            }}
                                            onEditorChange={(e) => {
                                                props.handleChange({ target: { name: 'description', value: e } });
                                            }}
                                        />
                                        <FormErrorMessage>{form.errors.description}</FormErrorMessage>
                                    </FormControl>
                                }/>
                                )}
                            </Field>

                            <div style={{ padding: "12px"}}>
                                <Button type="submit" bg={'brand.100'} color={'white'} size={'md'} 
                                    isLoading={props.isSubmitting} _hover={{ bg: 'brand.50' }}
                                    title="Send" style={{ marginRight: "10px"}}> Send
                                </Button>
                               <NavLink to="/custom-notifications">
                                    <Button type="button" variant='outline' title="Cancel">
                                        Cancel
                                    </Button>
                                </NavLink>
                            </div>
                        </Box>

                    </Form>
                    )}
                </Formik>
            </Card>
        </Box>
    )
}

export default SendNotification;
